<template>
  <span
    v-if="showTooltip"
    :data-tooltip="tooltipText"
    class="has-tooltip-left"
    :style="tooltipStyle">
  </span>
</template>

<script>
export default {
  name: 'TooltipStatus',
  props: {
    data: {
      type: Number,
      required: true
    },
    pending: {
      type: Number,
      required: true
    },
    complete: {
      type: Number,
      required: true
    }
  },
  computed: {
    showTooltip () {
      return this.data >= this.pending
    },
    tooltipStyle () {
      let backgroundColor = 'gray'

      if (this.data >= this.complete) {
        backgroundColor = 'green'
      } else if (this.data === this.pending) {
        backgroundColor = 'orange'
      }

      return {
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        marginLeft: '5px',
        backgroundColor: backgroundColor
      }
    },
    tooltipText () {
      if (this.data >= this.complete) {
        return 'Completado'
      } else if (this.data === this.pending) {
        return 'Pendiente'
      }
      return ''
    }
  }
}
</script>

<style scoped>
/* Aquí puedes agregar estilos adicionales si es necesario */
</style>
